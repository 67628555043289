export default {
  table_rows: 7, // Number of rows displayed in the table
  instantSeconds: 30, // displays 'sofort' in the table when "instantSeconds" are left
  auto_fetch_time: 15000, // refetching every x ms
  switch_time: 15000, // switch time in ms when more than 1 station is stored
  uffbasse_slider_switch_time: 6000, // Autoplay interval for the uffbasse slider in milliseconds
  activate_vrnstops: false, // global variable for activating vrnstops
  last_updated: '', // last updated time
  loading: true, // shows if data is beeing fetched
  allStations: {},
  currentHafasID: '',
  night: true,
  stationSpecificOverrides: {},
  currentVersion: '',
  theme: 'auto',
  requestedStations: ['2417'] // initilaised standart station/s when creating website
}
