<template>
  <div class="grid-container" v-bind:class="{ night: night }">
      <div class="header">
        <div class="station-name">
          <img src="@/assets/aushang_white.svg">
          <StationName />
        </div>
        <div class="logo">
          <img src="./assets/rnv-logo-small.svg">
        </div>
        <div class="time">
          <Clock />
        </div>
      </div>
      <div class="content">
        <div class="timetable">
          <router-view />
        </div>
        <Uffbasse />
      </div>
      <div class="footer">
        <Footer />
      </div>
    </div>
</template>

<script>
import Footer from '@/components/footer'
import { mapState } from 'vuex'
import Clock from '@/components/clock'
import StationName from '@/components/station-name'
import Uffbasse from '@/components/uffbasse'

export default {
  name: 'App',
  data () {
    return {
      interval: null,
      updateInterval: null
    }
  },
  components: {
    Footer,
    Clock,
    StationName,
    Uffbasse
  },
  computed: {
    ...mapState([
      'night'
    ])
  },
  methods: {
    fetchData: function () {
      this.$store.dispatch('getAllDeparturesForStation', this)
      this.$store.dispatch('checkNight')
    },
    runUpdateChecks: function () {
      // At the Moment, this may causes the DFI to show a blank screen.
      // Disabled until further notice.
      // this.$store.dispatch('checkForNewVersion')
    }
  },
  created () {
    this.$root.$refs.A = this
  },
  mounted () {
    this.interval = setInterval(this.fetchData, this.$store.state.auto_fetch_time)
    this.runUpdateChecks()
    this.updateInterval = setInterval(this.runUpdateChecks, 60 * 60 * 1000)
  },
  beforeDestroy () {
    clearInterval(this.interval)
    clearInterval(this.updateInterval)
  }
}
</script>

<style>
@font-face {
    font-family: 'PT Sans Narrow';
    src: url('./assets/PTSans-Narrow/PTSans-Narrow.eot'); /* IE9 Compat Modes */
    src: url('./assets/PTSans-Narrow/PTSans-Narrow.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('./assets/PTSans-Narrow/PTSans-Narrow.woff2') format('woff2'), /* Super Modern Browsers */
        url('./assets/PTSans-Narrow/PTSans-Narrow.woff') format('woff'), /* Pretty Modern Browsers */
        url('./assets/PTSans-Narrow/PTSans-Narrow.ttf') format('truetype'); /* Safari, Android, iOS */
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

#app {
  text-align: center;
}

html, body, .grid-container {
  height: 100%;
  margin: 0;
  font-family: "PT Sans Narrow", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--color-background, hsl(220, 8%, 95%));
  color: var(--color-text, #00254c);
}
/**
* Settings for the FAA DFI:
*/
@media screen and (max-height: 600px) {
  html {
    --header-height: 48px;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: var(--header-height, 100px) 1fr var(--footer-height, 24px);
  gap: 0px 0px;
  grid-template-areas:
    "header"
    "content"
    "footer";
}

.footer {
  grid-area: footer;
  background-image: url('./assets/rnv-footer-selfmade.svg');
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 1rem;
  padding: 0 1rem 0.33rem 1rem;
  width: 100%;
  height:var(--footer-height, 24px);
  background-color: #fff;
  text-align: left;
}

.content {
  grid-area: content;
  overflow: auto;
  display: inline-grid;
  grid-template-columns: 60vw 40vw;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas:
    "timetable uffbasse";
}
.timetable { grid-area: timetable; }
.timetable:only-child {
  grid-column: 1 / 3;
}
.uffbasse {
  grid-area: uffbasse;
}

.header {
  display: grid;
  grid-template-columns: auto 1fr 0.3fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas:
    "logo station-name time";
  grid-area: header;
  background-color: var(--color-header-background, #1a2947);
  color: var(--color-header-text, #fff);
  font-size: calc(var(--header-height, 100px) / 2)
}

.station-name {
  grid-area: station-name;
  display:flex;
  align-items: center;
  flex-direction: row;
  align-self: center;
  overflow: hidden;
}

.station-name img {
  height: calc(var(--header-height, 100px) / 1.5);
  margin-right: 20px;
}

.logo {
  grid-area: logo;
  padding-top: calc(var(--header-height, 100px) / 6);
}

.logo img {
  display: block;
  height: calc(var(--header-height, 100px) / 1.5);
  margin-right: 50px;
}

.time {
  grid-area: time;
  align-self: center;
  padding-right: 1rem;
  font-size: 1em
}

</style>
