import { mapState } from 'vuex'
export default {
  name: 'station-name',
  components: {},
  props: [],
  data () {
    return {
    }
  },
  computed: {
    ...mapState(['allStations', 'currentHafasID']),
    stationName () {
      if (this.allStations[this.currentHafasID]) {
        return this.allStations[this.currentHafasID].longName
      } else {
        return ''
      }
    }
  },
  mounted () {

  },
  methods: {

  }
}
