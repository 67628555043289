/**
   * Component for the line destination
   * @displayName Line Destination
   * @example <LineDestination :destination='Schönau' :cancelled='false' />
   */
export default {
  name: 'line-destination',
  components: {},
  props: [
    /**
       * The destination that will be displayed
       * @values any string
       */
    'destination',
    /**
       * Whether the journey is cancelled or not
       * @values true, false
       */
    'cancelled'],
  data () {
    return {
      /**
       * Will be displayed after the destination when cancelled
       */
      cancelString: ' entfällt'
    }
  },
  computed: {},
  mounted () {},
  methods: {}
}
