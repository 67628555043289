<template>
    <StationList />
</template>

<script>
import StationList from '@/components/StationList.vue'

export default {
  name: 'Home',
  data () {
    return {
    }
  },
  components: {
    StationList
  },
  created () {
    this.$root.$refs.A.fetchData()
  },
  mounted () {
  },
  computed: {
  },
  methods: {
  },
  beforeDestroy () {
  }
}
</script>

<style>
.home{
  color: var(--color-text, #00254c);
}

.currentStation{
  text-align: left;
}

.time{
  text-align: right;
}
</style>
