import { mapState } from 'vuex'

export default {
  name: 'uffbasse',
  components: {},
  props: [],
  data () {
    return {
    }
  },
  computed: {
    ...mapState(['currentHafasID', 'allStations', 'uffbasse_slider_switch_time']),
    /**
     * Checks if the station has any uffbasse.
     * @return {boolean} true when it has uffbasse, false otherwise
     */
    isVisible () {
      var hasUffbase = false
      if (Object.prototype.hasOwnProperty.call(this.allStations, this.currentHafasID)) {
        if (this.allStations[this.currentHafasID].uffbasses.length > 0) {
          hasUffbase = true
        }
      }
      return hasUffbase
    },
    /**
     * Activates autoplay when the station has more than 1 Uffbasse
     * @return {boolean} true when it has more than 1 Uffbasse, false otherwise
     */
    isAutoplay () {
      var auto = false
      if (this.allStations[this.currentHafasID].uffbasses.length > 1) {
        auto = true
      }
      return auto
    }
  },
  mounted () {
  },
  methods: {
  }
}
