
export default {
  name: 'line-icon',
  components: {},
  props: ['icon'],
  data () {
    return {}
  },
  computed: {},
  mounted () {},
  methods: {}
}
