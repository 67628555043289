<template>
  <div>
    <Header/>
    Settings
  </div>
</template>

<script>

export default {
  name: 'Settings',
  components: {
  }
}
</script>
