import { mapState } from 'vuex'

export default {
  name: 'footer',
  components: {},
  props: [],
  data () {
    return {
    }
  },
  computed: {
    ...mapState([
      'last_updated'
    ])
  },
  mounted () {
  },
  methods: {
  }
}
