import { mapState } from 'vuex'

export default {
  name: 'departure-time',
  components: {},
  props: ['plannedDeparture', 'realtimeDeparture', 'secondsUntilDeparture'],
  data () {
    return {}
  },
  computed: {
    ...mapState([
      'instantSeconds'
    ]),
    /**
     * checks of the departure time should be displayed as realtime or planned
     * @returns {string} the parsed departure time
     */
    departureTime () {
      if (this.realtimeDeparture != null) {
        return this.parseRealtimeDeparture()
      } else if (this.plannedDeparture != null) {
        return this.parsePlannedDeparture(this.plannedDeparture)
      }
    }
  },
  mounted () {},
  methods: {
    /**
     * Parses the departure time for realtime in one of 2 forms
     * 1. when realtime and (under 30min : "in XX Min" or under "instantSeconds" : "sofort")
     * 2. when realtime and over 30min : "mm:hh"
     * @returns {string} - the parsed departure time
     */
    parseRealtimeDeparture: function () {
      const seconds = this.secondsUntilDeparture
      if (seconds <= this.instantSeconds) {
        return 'sofort'
      } else if (seconds <= 60 * 30) {
        return `in ${Math.round(seconds / 60)} Min`
      } else {
        return this.parsePlannedDeparture(this.realtimeDeparture)
      }
    },
    /**
     * Parses the departure time for only planned times in the form "mm:hh"
     * @param {number} secondsSinceEpoch - departure time in seconds since epoch
     * @returns {string} - the parsed departure time
     */
    parsePlannedDeparture: function (secondsSinceEpoch) {
      const departure = new Date(secondsSinceEpoch * 1000)
      return `${this.zeroPadding(departure.getHours(), 2)}:${this.zeroPadding(departure.getMinutes(), 2)}`
    },
    /**
     * fills num with (digit-1) zeroes from the left
     * @param {number} num - the number which we want to fill with zeroes
     * @param {number} digit - how many digits the returned number should be
     * @returns {string} the number which got filled with zeroes from the left
     */
    zeroPadding: function (num, digit) {
      var zero = ''
      for (var i = 0; i < digit; i++) {
        zero += '0'
      }
      return (zero + num).slice(-digit)
    }
  }
}
