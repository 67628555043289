import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Settings from '../views/Settings.vue'
import Selection from '../views/Selection.vue'
// import DisplayMode from '../views/DisplayMode.vue'
import store from '../store/index.js'

Vue.use(VueRouter)

function parseQueryParameters (route) {
  if (route.query.rows) {
    store.commit('writeRows', parseInt(route.query.rows))
  }

  if (route.query.vrn === 'true') {
    store.commit('writeVrn', route.query.vrn)
  }
  if (route.query.switchtime) {
    store.commit('writeSwitchTime', parseInt(route.query.switchtime) * 1000)
  }

  if (route.query.hafasid) {
    store.dispatch('transformRequestedStations', route.query.hafasid)
  }
  if (route.query.hafasid && route.query.stationNames) {
    store.dispatch('transformAndMapStationNames', {
      hafasID: route.query.hafasid,
      stationNames: route.query.stationNames
    })
  }
  if (route.query.theme) {
    store.dispatch('checkTheme', route.query.theme)
  }
  if (route.query.uffbasseSliderSwitchtime) {
    store.commit('writeUffbasseSliderSwitchTime', parseInt(route.query.uffbasseSliderSwitchtime) * 1000)
  }
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/selection',
    name: 'Selection',
    component: Selection
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  },
  {
    path: '/displaymode',
    name: 'DisplayMode',
    props: parseQueryParameters,
    component: () => import('../views/DisplayMode.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
