<template>
    <StationList />
</template>

<script>
import { mapState } from 'vuex'
import StationList from '@/components/StationList.vue'

export default {
  name: 'DisplayMode',
  data () {
    return {
      switch_interval: null
    }
  },
  components: {
    StationList
  },
  created () {
    this.$root.$refs.A.fetchData()
  },
  mounted () {
    this.switch_interval = setInterval(this.incrementID, this.$store.state.switch_time)
  },
  computed: {
    ...mapState(['allStations'])
  },
  methods: {
    incrementID: function () {
      this.$store.dispatch('nextStation')
    }
  },
  beforeDestroy () {
    clearInterval(this.switch_interval)
  }
}
</script>

<style>
.home {
  color: var(--color-text, #00254c);
}

.currentStation {
  text-align: left;
}

.time {
  text-align: right;
}

.station-header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: var(--color-header-background, var(--color-background, #fff));
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 32px;
  height: 48px;
  overflow: hidden;
}
</style>
