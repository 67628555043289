/* eslint-disable */
import { getSunrise, getSunset } from 'sunrise-sunset-js'
import {CLIENT_API_URL} from '@/env2.js'

export default {
  setVRN_Data(state,payload) {
    let lines = payload.lines
    for (var key in lines) {
      if (lines.hasOwnProperty(key) && key.substring(0,3) === 'VRN') {
        if(lines[key].plannedDepartureInSeconds || lines[key].realtimeDepartureInSeconds){
          state.commit('addLines', {
            hafasID: payload.hafasID,
            type: lines[key].type,
            productType: lines[key].productType,
            journeyID: lines[key].journeyID,
            name: lines[key].name.split(/\s(.+)/)[0],
            destination: lines[key].destination,
            platform: lines[key].platform,
            plannedDepartureInSeconds: lines[key].plannedDepartureInSeconds,
            realtimeDepartureInSeconds: lines[key].realtimeDepartureInSeconds,
            departureInSeconds: lines[key].realtimeDepartureInSeconds ? lines[key].realtimeDepartureInSeconds : lines[key].plannedDepartureInSeconds,
            secondsUntilDeparture: 0,
            primaryColor: lines[key].primaryColor == null ? '#888888': lines[key].primaryColor,
            secondaryColor: lines[key].secondaryColor == null ? '#FFFFFF' : lines[key].secondaryColor,
            cancelled: lines[key].cancelled
          })
          state.commit('updateSecondsUntilDeparture')
        }
      }
    }
  },
  setData(state, payload) {
    let lines = payload.lines
    for (var key in lines) {
      if (lines.hasOwnProperty(key) && key.substring(0,3) !== 'VRN') {
        if(lines[key].plannedDepartureInSeconds || lines[key].realtimeDepartureInSeconds){
          state.commit('addLines', {
            hafasID: payload.hafasID,
            type: lines[key].type,
            productType: lines[key].productType,
            journeyID: lines[key].journeyID,
            name: lines[key].name,
            destination: lines[key].destination,
            platform: lines[key].platform,
            plannedDepartureInSeconds: lines[key].plannedDepartureInSeconds,
            realtimeDepartureInSeconds: lines[key].realtimeDepartureInSeconds,
            departureInSeconds: lines[key].realtimeDepartureInSeconds ? lines[key].realtimeDepartureInSeconds : lines[key].plannedDepartureInSeconds,
            secondsUntilDeparture: 0,
            primaryColor: lines[key].primaryColor == null ? '#888888': lines[key].primaryColor,
            secondaryColor: lines[key].secondaryColor == null ? '#FFFFFF' : lines[key].secondaryColor,
            cancelled: lines[key].cancelled
          })
          state.commit('updateSecondsUntilDeparture')
        }
      }
    }
  const time = refreshTime()
  state.commit('writeLastUpdated',time)
  },
  /**
   * Fetches all Departures for the stations in the store. 
   * @param {*} param0 Will be filled automatically
   */
  getAllDeparturesForStation ({dispatch, commit, state}) {
    const axios = require('axios');
    state.requestedStations.map(async (hafasID) => {
      axios
        .get(CLIENT_API_URL + '?hafasid=' + hafasID)
        .then(response => {
          const data = response.data
          commit('addStation', data)
          dispatch('setData', data)
          if (state.activate_vrnstops && data.hasVRNStops) {
            dispatch('setVRN_Data', data)
          }
        })
    })
  },
  nextStation (context) {
    const hafasIDs = Object.keys(context.state.allStations)
    // if Next station is not initialized, set it to first.:
    if (context.state.currentHafasID === '') {
      context.commit('writeCurrentHafasID', hafasIDs[0])
    } else {
      if(hafasIDs.length > 1) {
        // Otherwise, there is already a station setted up, so we select the next one.
        const nextIndex = hafasIDs.indexOf(context.state.currentHafasID) + 1
        // Check if next index is defined, if not start at 0:
        const nextHafasID = hafasIDs[nextIndex] ? hafasIDs[nextIndex] : hafasIDs[0]
        context.commit('writeCurrentHafasID', nextHafasID)
      }
    }
  },
  transformRequestedStations (context, payload) {
    context.commit('writeRequestedStations', payload.split(','))
  },
  /**
   * Trys to map the query parameters of hafasID together with station names.
   * TODO: Not sure if action is correct place, maybe better in a mutation.
   * @param {*} context 
   * @param {*} payload expects an Object with String keys hafasID and stationName
   */
  transformAndMapStationNames (context, payload) {
    let stationSpecificOverrides = context.state.stationSpecificOverrides
    const hafasID = payload.hafasID.split(',')
    const stationNames = payload.stationNames.split(',')

    hafasID.forEach((station, index) => {
      if(stationNames[index]) {
        if (typeof stationSpecificOverrides[station] === 'undefined') {
          stationSpecificOverrides[station] = {}
        }
        stationSpecificOverrides[station].name = stationNames[index]
      }
    })
    context.commit('writreStationSpecificOverrides', stationSpecificOverrides)
  },
  checkNight ({dispatch, commit, state}) {
    if (state.theme === 'auto'){
      // Mannheim
      const lat = 49.483752
      const long = 8.476682
      const sunset = getSunset(lat, long)
      const sunrise = getSunrise(lat, long)
      const now = new Date()
      if (sunrise < now && now < sunset) {
        // It's Day!
        commit('writeNight', false)
      } else {
        // It's Night.
        commit('writeNight', true)
      }
    }
  },
  async checkForNewVersion({dispatch, commit, state}) {
    const currentVersion = state.currentVersion || ''
    const response = await fetch('/COMMITHASH')
    const onlineVersion = await response.text()
    // The first time we run version check, it is expected to be empty, as the application has just booted.
    if(currentVersion == '') {
      commit('writeCurrentVersion', onlineVersion)
    } else {
      // if we have a version, Let's check if it is different than that what we have got from our server.
      if(currentVersion != onlineVersion) {
        // This Method is not safe to update the Application.
        // We need to Look for a safer method here.
        //window.location.reload(true)
      }
    }
  },
  async checkTheme({dispatch, commit, state},payload){
    commit('writeTheme', payload)
    if (payload === 'day') {
      commit('writeNight', false)
    } else if (payload === 'night') {
      commit('writeNight', true)
    } else if (payload === 'system' && window.matchMedia) {
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        commit('writeNight', true)
      } else if (window.matchMedia('(prefers-color-scheme: light)').matches) {
        commit('writeNight', false)
      } else {
        return
      }
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
        const mode = e.matches ? "dark" : "light"
        if(mode === 'dark'){
          commit('writeNight', true)
        } else {
          commit('writeNight', false)
        }
      })
    } else {
      commit('writeTheme', 'auto')
    }
  }
}
/**
 * returns the current time in the format "mm:hh"
 * @returns {string} the current time 
 */
function refreshTime(){
  const today = new Date()
  const time = `${zeroPadding(today.getHours(), 2)}:${zeroPadding(today.getMinutes(), 2)}`
  return time
}
/**
 * fills num with (digit-1) zeroes from the left
 * @param {number} num - the number which we want to fill with zeroes
 * @param {number} digit - how many digits the returned number should be
 * @returns {string} the number which got filled with zeroes from the left
 */
function zeroPadding (num, digit) {
  var zero = ''
  for (var i = 0; i < digit; i++) {
    zero += '0'
  }
  return (zero + num).slice(-digit)
}
