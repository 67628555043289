/* eslint-disable */
import Vue from 'vue'

export default {
  // sync
  addStation (state, payload) {
    const stationStore = state.allStations
    const stationSpecificOverrides = state.stationSpecificOverrides
    const stationObject = {
      longName: payload.longName,
      name: payload.name,
      hasVRNStops: payload.hasVRNStops,
      uffbasses: [],
      lines: {}
    }

    for (var key in payload.stationInfo) {
      if (payload.stationInfo.hasOwnProperty(key)) {
        stationObject.uffbasses.push(payload.stationInfo[key])
      }
    }
    // Check if stationNames are overwritten.
    if (stationSpecificOverrides[payload.hafasID]) {
      if (stationSpecificOverrides[payload.hafasID].name) {
        stationObject.name = stationSpecificOverrides[payload.hafasID].name
        stationObject.longName = stationSpecificOverrides[payload.hafasID].name
      }
    }

    if (stationStore[payload.hafasID]) {
      stationObject.lines = stationStore[payload.hafasID].lines
    }
    Vue.set(state, 'loading', false)
    Vue.set(stationStore, payload.hafasID, stationObject)
    // If no station is there, set this one:
    if (!state.currentHafasID) {
      Vue.set(state, 'currentHafasID', payload.hafasID)
    }
  },
  addLines (state, payload) {
    const stationStore = state.allStations
    if (!stationStore[payload.hafasID]) {
      Vue.set(stationStore, payload.hafasID, {})
      Vue.set(stationStore[payload.hafasID], 'lines', {})
    }
    Vue.set(stationStore[payload.hafasID].lines, payload.journeyID, payload)
  },
  updateSecondsUntilDeparture (state) {
    const now = new Date()
    Object.keys(state.allStations).map((key) => {
      if (state.currentHafasID !== 0) {
        const lines = state.allStations[key].lines
        Object.keys(lines).map(function (key) {
          const secondsSinceEpoch = Math.round(now.getTime() / 1000)
          lines[key].secondsUntilDeparture = lines[key].departureInSeconds - secondsSinceEpoch
          // Purge lines that are off the timetable.
          if (lines[key].secondsUntilDeparture < 0) {
            delete lines[key]
          }
        })
      }
    })
  },
  writeLastUpdated (state, payload) {
    state.last_updated = payload
  },
  writeRows (state, payload) {
    state.table_rows = payload
  },
  writeVrn (state, payload) {
    state.activate_vrnstops = payload
  },
  writeSwitchTime (state, payload) {
    state.switch_time = payload
  },
  writeCurrentHafasID (state, payload) {
    state.currentHafasID = payload
  },
  writeRequestedStations (state, payload) {
    state.requestedStations = payload
  },
  /**
   * This will set if it is night or not in the global state.
   * Has influence on the styling of the app. (Dark and Light theme)
   * @param {*} state Will be filled automatically
   * @param {Boolean} payload weather it is night or not.
   */
  writeNight (state, payload) {
    state.night = payload
  },
  writeCurrentVersion (state, payload) {
    state.currentVersion = payload
  },
  writreStationSpecificOverrides (state, payload) {
    state.stationSpecificOverrides = payload
  },
  writeTheme (state, payload) {
    state.theme = payload
  },
  /**
   * This will set the slider switchtime for the uffbasse component in the global state.
   * Has influence on speed of the slider.
   * @param {*} state Will be filled automatically
   * @param {Number} payload time in ms
   */
  writeUffbasseSliderSwitchTime (state, payload) {
    state.uffbasse_slider_switch_time = payload
  }
}
