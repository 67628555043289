
export default {
  name: 'line-id',
  components: {},
  props: ['icon', 'line', 'primaryColor', 'secondaryColor', 'float'],
  data () {
    return {}
  },
  computed: {},
  mounted () {},
  methods: {}
}
