<template>
    <b-table class='list' striped :busy="loading" :items="table()" :fields="fields">
      <template #table-busy>
        <div class="spinner">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template v-slot:cell(line)='data'>
        <div><LineId :float="'right'" :icon='data.item.icon' :line='data.item.line' :primaryColor='data.item.primaryColor' :secondaryColor='data.item.secondaryColor'  /></div>
      </template>
      <template v-slot:head(line)='data'>
        <div class='table-right'>{{ data.label }}</div>
      </template>
      <template v-slot:cell(icon)='data'>
        <LineIcon :icon='data.item.icon' />
      </template>
      <template v-slot:head(icon)='data'>
        <div>{{ data.label }}</div>
      </template>
      <template v-slot:head(dest)='data'>
        <div class='table-left'>{{ data.label }}</div>
      </template>
      <template v-slot:cell(dest)='data'>
        <div class='table-left'><LineDestination :destination='data.item.dest' :cancelled='data.item.cancelled' /></div>
      </template>
      <template v-slot:cell(platform)='data'>
        <div class='table-right'>{{ data.item.platform }}</div>
      </template>
      <template v-slot:head(platform)='data'>
        <div class='table-right'>{{ data.label }}</div>
      </template>
      <template v-slot:head(departure)='data'>
        <div class='table-center'>{{ data.label }}</div>
      </template>
      <template v-slot:cell(departure)='data'>
        <div class='table-center'><DepartureTime :secondsUntilDeparture='data.item.sec' :plannedDeparture='data.item.plannedDeparture' :realtimeDeparture='data.item.realtimeDeparture' /></div>
      </template>
    </b-table>
</template>

<script>
/* eslint-disable no-debugger */
import { mapState } from 'vuex'
import LineId from '@/components/line-id'
import LineIcon from '@/components/line-icon'
import DepartureTime from '@/components/departureTime'
import LineDestination from '@/components/line-destination'

export default {
  name: 'StationList',
  data () {
    return {
      intervall: null,
      fields: [
        {
          key: 'line',
          label: 'Linie',
          thStyle: {
            width: '7.5%'
          }
        },
        {
          key: 'icon',
          label: '',
          thStyle: {
            width: '7.5%'
          }
        },
        {
          key: 'dest',
          label: 'Ziel',
          thStyle: {
            width: '45%'
          }
        },
        {
          key: 'platform',
          label: 'Steig / Gleis',
          thStyle: {
            width: '20%'
          }
        },
        {
          key: 'departure',
          label: 'Abfahrt',
          thStyle: {
            width: '20%'
          }
        }
      ]
    }
  },
  components: {
    LineId,
    LineIcon,
    DepartureTime,
    LineDestination
  },
  props: {
  },
  mounted () {
    this.interval = setInterval(this.updateAllDepartures, 5000)
  },
  methods: {
    updateAllDepartures () {
      this.$store.commit('updateSecondsUntilDeparture')
    },
    /**
     * checks if a platform filter query parameter is applied, and returns false if the requested platform is not in query string.
     * This Method is case sensitiv!
     * @param platform - string of the platform to check, if included in query string
     */
    checkPlatform (platform) {
      return this.$route.query.platform ? this.$route.query.platform.includes(platform) : true
    },
    table: function () {
      if (this.$store.state.currentHafasID && this.$store.state.currentHafasID !== '') {
        let items = []
        const stations = this.$store.state.allStations
        const tableRows = this.$store.state.table_rows
        const currentID = this.$store.state.currentHafasID
        const currentStation = stations[currentID]
        for (const [, line] of Object.entries(currentStation.lines)) {
          if (this.checkPlatform(line.platform)) {
            items.push({
              line: line.name,
              icon: line.type,
              dest: line.destination,
              platform: line.platform,
              sec: line.secondsUntilDeparture,
              primaryColor: line.primaryColor,
              secondaryColor: line.secondaryColor,
              realtimeDeparture: line.realtimeDepartureInSeconds,
              plannedDeparture: line.plannedDepartureInSeconds,
              cancelled: line.cancelled
            })
          }
        }
        // sort
        items.sort(function (a, b) {
          return a.sec - b.sec
        })
        items = items.slice(0, tableRows)
        return items
      } else {
        return null
      }
    }
  },
  computed: {
    ...mapState([
      'allStations',
      'currentHafasID',
      'loading'
    ])
  },
  beforeDestroy () {
    clearInterval(this.interval)
  }
}
</script>

<style>
.list {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 2rem;
  /* margin-bottom: 24px; /* footer height */
  /* margin-top: 48px; /* header height */
  color: var(--color-text, #00254c);
  background: var(--color-list-background, #FFFFFF);
  --icon-size: 40px;
  height: 100%;
  text-align: center;
}

.table {
  margin-bottom: 0;
}

.table-left {
  text-align: left;
}

.table-right {
  text-align: right;
}

.spinner{
  color: var(--color-text, #00254c);
}

/* Busy table styling */
table.b-table[aria-busy='true'] {
  opacity: 0.6;
}

.table th {
  padding: 0;
  font-size: 2rem;
  border: none;
}

.table td {
  padding: 0.35rem;
  vertical-align: middle;
  border: none;
}
</style>
