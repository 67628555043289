
export default {
  name: 'clock',
  components: {},
  props: [],
  data () {
    return {
      timestamp: ''
    }
  },
  computed: {

  },
  mounted () {
    this.getNow()
    this.interval = setInterval(this.getNow, 1000)
  },
  methods: {
    getNow: function () {
      const today = new Date()
      const time = `${this.zeroPadding(today.getHours(), 2)}:${this.zeroPadding(today.getMinutes(), 2)}`
      this.timestamp = time
    },
    zeroPadding (num, digit) {
      var zero = ''
      for (var i = 0; i < digit; i++) {
        zero += '0'
      }
      return (zero + num).slice(-digit)
    }
  },
  beforeDestroy () {
    clearInterval(this.interval)
  }
}
